jQuery(document).ready(function ($) {

    var $w = $(window),
        $canvases = $('body');
    var nav = document.querySelector("#nav");

    $w.scroll(function () {
        var d = $w.height() / 2 + $w.scrollTop();
        $canvases.each(function () {
            var $this = $(this),
                ptop = $this.offset().top,
                total = ptop + $this.height();
            if (d < 750) {
                nav.classList.remove("animated", "fadeIn", "navbar-fixed");
            }
            else {
                nav.classList.add("animated", "fadeIn", "navbar-fixed");
            }
            $this.toggleClass('active', ptop < d && total >= d);

            transform = 'translateY(' + -d / 6 + 'px)';
            $('#shape1').css('transform', transform);

            transform2 = 'translateY(' + -d / 4 + 'px)';
            $('#women2').css('transform', transform2);

            transform3 = 'translateY(' + -d / 5 + 'px)';
            $('#shape2').css('transform', transform3);

            transform4 = 'translateY(' + d / 10 + 'px)';
            $('#shape3').css('transform', transform4);

            transform5 = -d / 7;
            $('.anmText').css('background-position-y', transform5);
        });
    }).scroll(); //trigger the handler when DOM is ready

});